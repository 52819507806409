import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";
import { useEffect } from "react";

import "./index@gen.scss";
import HomeCompanies from "../components/shortcodes/HomeCompanies";
import ShortNews from "../components/shortcodes/ShortNews";
import "../components/shortcodes/ShortNews.scss";

const GeneratedIndex = () => {
  useEffect(() => {
    let toggle1 = document.querySelector(".faq-toggle-1");
    let toggle2 = document.querySelector(".faq-toggle-2");
    let toggle3 = document.querySelector(".faq-toggle-3");
    let answer1 = document.querySelector(".faq-answer-1");
    let answer2 = document.querySelector(".faq-answer-2");
    let answer3 = document.querySelector(".faq-answer-3");

    toggle1.addEventListener("click", () => {
      if (answer1.style.display === "none") {
        answer1.style.display = "block";
        toggle1.classList.add("home-faq-icon-rotate");
      } else {
        answer1.style.display = "none";
        toggle1.classList.remove("home-faq-icon-rotate");
      }
    });
    toggle2.addEventListener("click", () => {
      if (answer2.style.display === "none") {
        answer2.style.display = "block";
        toggle2.classList.add("home-faq-icon-rotate");
      } else {
        answer2.style.display = "none";
        toggle2.classList.remove("home-faq-icon-rotate");
      }
    });
    toggle3.addEventListener("click", () => {
      if (answer3.style.display === "none") {
        answer3.style.display = "block";
        toggle3.classList.add("home-faq-icon-rotate");
      } else {
        answer3.style.display = "none";
        toggle3.classList.remove("home-faq-icon-rotate");
      }
    });
  }, []);

  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDuty（ページャーデューティー）｜正規販売代理店 DXable
        </title>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "PagerDutyとは何ですか？",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "PagerDutyは監視ツールやアプリケーションからのアラートを受けて、いろいろな方法でインシデントの発生を確実に担当者に通報するシステムです。ダウンタイムやシステムの不正確さから企業を保護するサービスで、他の監視ツールとの統合も可能。チーム全体にとって必要な警告～警報に優先順位を付け、メールやSMS、音声メッセージなどでアラート送信してくれます。",
                },
              },
              {
                "@type": "Question",
                name: "PagerDutyの費用は?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Professional プランで21ドル（1ユーザー／月：年払い）、Businessプラン41ドル（1ユーザー／月：年払い）になります。詳しくは<a href='https://pagerduty.dxable.com/pricelist/'> プライスリスト</a>でご確認ください",
                },
              },
              {
                "@type": "Question",
                name: "14 日間の無料トライアルはどんなことができますか？",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "無料トライアル期間中は、全部の機能が使えて、電話やSMSを無料で送信できます。ご購入するまではクレジットカード登録も不要ですので課金されることはありません。無料トライアルの申し込みは<a href='https://pagerduty.dxable.com/free-trial-2/?utm_source=google&utm_medium=faq&utm_campaign=g_search01'>こちら</a>",
                },
              },
            ],
          })}
        </script>
        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDutyは監視ツールやアプリケーションからのアラートを受けて、いろいろな方法でインシデントの発生を確実に担当者に通報するシステムです。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDutyは監視ツールやアプリケーションからのアラートを受けて、いろいろな方法でインシデントの発生を確実に担当者に通報するシステムです。"
        />

        <meta
          property="og:title"
          content="PagerDuty（ページャーデューティー）｜正規販売代理店 DXable"
        />

        <meta
          property="og:description"
          content="PagerDutyは監視ツールやアプリケーションからのアラートを受けて、いろいろな方法でインシデントの発生を確実に担当者に通報するシステムです。"
        />
        <meta property="og:url" content="https://pagerduty.dxable.com/" />
        <meta property="twitter:url" content="https://pagerduty.dxable.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <div className="indexWrapper">
        <div className="hero-container md:bg-[url('https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/home_banner_image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676024176595&tr=q-100,f-webp')] bg-right-bottom">
          <div className="hero-header">
            <h1 className="banner-title">
              嵐のようなシステムアラートを
              <br />
              まとめて通知
            </h1>
          </div>
          <div className="hero-sub-header">
            <p>
              インシデント管理ソリューション「PagerDuty」は運用担当者の負担を劇的に軽減します。
            </p>
          </div>
          <div className="hero-btn-group">
            <button className="hero-primary-btn group">
              <a href="/free-trial-2/?icid=mainmenu" className="mr-2">
                {" "}
                無料トライアル{" "}
              </a>
              <svg
                width="34px"
                height="34px"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="17px" cy="17px" r="17px" fill="#4EB346" />
                <path
                  d="M26.7071 17.7071C27.0976 17.3166 27.0976 16.6834 26.7071 16.2929L20.3431 9.92893C19.9526 9.53841 19.3195 9.53841 18.9289 9.92893C18.5384 10.3195 18.5384 10.9526 18.9289 11.3431L24.5858 17L18.9289 22.6569C18.5384 23.0474 18.5384 23.6805 18.9289 24.0711C19.3195 24.4616 19.9526 24.4616 20.3431 24.0711L26.7071 17.7071ZM6 18L26 18L26 16L6 16L6 18Z"
                  fill="white"
                />
              </svg>
            </button>
            <button className="hero-secondary-btn">
              <a href="/contact-jp/">お問い合せ</a>
            </button>
          </div>
        </div>
        <div className="about-container">
          <div className="about-text-part">
            <div className="about-text-header After">
              <h2>PagerDutyとは​</h2>
            </div>
            <div className="about-text-desc">
              <p>
                PagerDutyは、複数の監視ツールと連携し数多く発せられるアラートの中から、機械学習やルーティングロジックを適用して必要なアラートだけをインシデント化し管理できるツールです。インシデント対応処理が開始されると、その日のオンコールエンジニアや、インシデントに対して適切な対応チームやメンバーに、電話、メール、slackなど様々な手段で通知をいたします。
              </p>
            </div>
          </div>
          <div className="about-option-part">
            <div className="about-options">
              <div className="about-options-item">
                <a href="/product/features/">PagerDutyの製品機能​</a>
              </div>
              <div className="about-options-item">
                <a href="/product/applications/">操作画面の特徴​</a>
              </div>
              <div className="about-options-item">
                <a href="/product/developers/">開発者の責任​</a>
              </div>
              <div className="about-options-item">
                <a href="/product/use-cases/">IT部門ごとのPagerDuty活用法​</a>
              </div>
              <div className="about-options-item">
                <a href="/product/it-operations/"> ITの運用​ </a>
              </div>
              <div className="about-options-item">
                <a href="/video/">ビデオで学ぶPagerDuty</a>
              </div>
            </div>
          </div>
        </div>
        <div className="category-container">
          <div className="category-header">
            <h2>代表的な課題</h2>
          </div>
          <div className="category-section bg-[#EAEAEA]">
            <div className="category-item">
              <div className="category-item-title">
                <h4>
                  01.
                  <br />
                  運用プロセスの穴
                </h4>
              </div>
              <div className="category-item-img">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/category1_483657b462_ca139c805e.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672968590974&tr=q-100"
                  alt="運用プロセスの穴"
                />
              </div>
              <div className="category-item-desc">
                対応フロー、使用するソフトウェア、連絡・確認体制が対応しきれておらず障害に気づくのが遅い。障害の連絡がない。対応者が分からない。ミスや抜けが発生。
              </div>
            </div>
            <div className="category-item">
              <div className="category-item-title">
                <h4>
                  02.
                  <br />
                  膨大なアラート
                </h4>
              </div>
              <div className="category-item-img">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/category2_17d2641d4a_1_e3b544ee40.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672968806906&tr=q-100"
                  alt="膨大なアラート"
                />
              </div>
              <div className="category-item-desc">
                モニタリングソフトウェアの発信するアラート数が多く、メールの仕分けやチャットツールの確認に時間がかかる。本当に重要なアラートの見落としも。
              </div>
            </div>
            <div className="category-item">
              <div className="category-item-title">
                <h4>
                  03.
                  <br />
                  時間の有効活用
                </h4>
              </div>
              <div className="category-item-img">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/category3_64e6a1f5e7_1_d7de96fe2e.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672968806729&tr=q-100"
                  alt="時間の有効活用"
                />
              </div>
              <div className="category-item-desc">
                現場は、障害対応に時間がとられ、本業である開発やリリースに集中しづらい。管理者は部下の就業時間や本業の開発速度が課題になりがち。
              </div>
            </div>
            <div className="category-triangle top-[97%] left-[50%] -translate-x-[50%]">
              <img
                loading="lazy"
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/triangle_Gray_b3e11b2b3d.png"
                alt="Triangle Gray"
              />
            </div>
          </div>
        </div>
        <div className="service-container">
          <div className="service-text-part">
            <div className="service-index">
              <h3>
                <span className="text-[#4EB346]">01.</span>
                <br />
                アプリケーションのアラートを確実に通知
              </h3>
            </div>
            <div className="service-desc">
              DXによりWebサービスなしでは動かない時代。絶対落ちないシステムはありえないのに、求められるのは24x365「ダウンタイムゼロ」。ただでさえエンジニア不足がひどい昨今。嵐のようなアラートを集約し、確実に適切な担当者に通知されることを実現するのがPagerDutyです。
            </div>
          </div>
          <div className="service-img-part">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/service1_9615910285_0b80900faf.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672970890504&tr=q-100"
              alt="アプリケーションのアラートを確実に通知"
            />
          </div>
        </div>
        <div className="service-container">
          <div className="service-img-part">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/service2_c876860b9f_a3fc5ccc6c.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672970890492&tr=q-100"
              alt="通知ルールの設定は柔軟に"
            />
          </div>
          <div className="service-text-part">
            <div className="service-index">
              <h3>
                <span className="text-[#4EB346]">02.</span>
                <br />
                通知ルールの設定は柔軟に
              </h3>
            </div>
            <div className="service-desc">
              PagerDutyは監視ツールやアプリケーションからのアラートを受けて、いろいろな方法でインシデントの発生を確実に担当者に通報するシステムです。通報はメールだけでなく音声による電話、SMS、スマホへのプッシュ型メッセージで行うことも可能です。
            </div>
          </div>
        </div>
        <div className="service-container">
          <div className="service-text-part">
            <div className="service-index">
              <h3>
                <span className="text-[#4EB346]">03.</span>
                <br />
                ご利用中のツールと簡単に連携、APIでアラート通知が可能
              </h3>
            </div>
            <div className="service-desc">
              PagerDutyはAWSのCloudWatchやZabbix、Nagios、Mackerelなど、多くの監視ツールと簡単に連携が可能です。REST
              APIでのコールもできますので、特定のアプリケーションを使用したインシデント報告をユーザーがプログラムすることも簡単です。もちろん、アプリケーションからPagerDutyのメール通知設定をすることもできますので、簡単なスクリプトを書くことで複雑なエスカレーションが可能です。
            </div>
          </div>
          <div className="service-img-part">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/service3_0dfcdc9873_c6f949ae00.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672970890446&tr=q-100"
              alt="ご利用中のツールと簡単に連携、APIでアラート通知が可能"
            />
          </div>
        </div>
        <div className="category-container">
          <div className="category-section category-section-2 bg-[#4EB346] text-white">
            <div className="category-item">
              <div className="category-item-title category-item-title-2">
                <h4>
                  運用状況をレ
                  <br />
                  ポート機能で可視化
                </h4>
              </div>
              <div className="category-item-img">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/service4_e22cac87eb_82543acbd3.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672977084755&tr=w-120,h-120,q-100,f-webp"
                  alt="運用状況をレ ポート機能で可視化"
                />
              </div>
              <div className="category-item-desc">
                レポート機能でインシデント数や対応に着手するまでの時間、解決までの時間などをリストやグラフで知ることが可能。インシデントが起きやすいアプリケーションやサービス全体の運用状況を定量的に管理するのに役立ちます。
              </div>
            </div>
            <div className="category-item">
              <div className="category-item-title category-item-title-2">
                <h4>
                  ビジュアル化された
                  <br />
                  コンソール
                </h4>
              </div>
              <div className="category-item-img">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/service5_0b79db96bd_1f66c44ae6.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672977084768&tr=w-120,h-120,q-100,f-webp"
                  alt="ビジュアル化された コンソール"
                />
              </div>
              <div className="category-item-desc">
                Webベースのクラウドサービスなのでハードウェアインフラ・アプリケーション・インシデントの状況をダッシュボードでリアルタイムに確認可能。監視ツールとの連携もコードを書かずにWebインターフェイスで簡単設定。
              </div>
            </div>
            <div className="category-item">
              <div className="category-item-title category-item-title-2">
                <h4>どこにいても安心​</h4>
              </div>
              <div className="category-item-img">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/service6_2a37b7cb51_b0a652f672.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672980377455&tr=w-120,h-120,q-100,f-webp"
                  alt="どこにいても安心​"
                />
              </div>
              <div className="category-item-desc">
                モバイルアプリもAndroid、iOS
                ともに用意されているので、どこにいても確認・解決・再割り当てが可能。
                アプリ・メール・SMS・電話などのマルチチャネル通信オプション使用で、アラートやインシデントの更新を見逃しません。
              </div>
            </div>
            <div className="category-triangle -top-[1%] left-[50%] -translate-x-[50%] h-[64px]">
              <img
                loading="lazy"
                className="h-[32px]"
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/triangle_White_a6049dde2c.png"
                alt="category triangle"
              />
            </div>
          </div>
        </div>
        <div className="blog-container">
          <div className="blog-header">
            <h2>お客様の声</h2>
          </div>
          <div className="blog-section">
            <div className="blog-item md:max-h-[330px]">
              <div className="blog-img w-[106px] min-h-[70px]">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/mixi.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676848708877&tr=q-100,f-webp"
                  alt="mixi"
                />
              </div>
              <div className="blog-item-desc">
                巨大インフラ、システムの運用にPagerDutyを活用しているSREチームのエンジニアに使用感を尋ねた。​
              </div>
              <div className="blog-item-cta">
                <a href="/casestudy-mixi/" className="mr-3 group flex">
                  詳しくはこちら
                  <svg
                    className="mt-[3px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="blog-item md:max-h-[330px]">
              <div className="blog-img w-[286px] min-h-[70px]">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/vega_corporation_d7c1f6f9d5.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672971208550&tr=q-100"
                  alt="VEGA corporation"
                />
              </div>
              <div className="blog-item-desc">
                一瞬の停止が売り上げを毀損するECサイトだけに24時間365日の運用が求められる中で、PagerDutyを採用することにした。
              </div>
              <div className="blog-item-cta">
                <a
                  href="/casestudy-vega-corporation/"
                  className="mr-3 group flex"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[3px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="blog-item md:max-h-[330px]">
              <div className="blog-img w-[249px] min-h-[70px]">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/smart_news_b7fb18307a.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672971208202&tr=q-100,f-webp"
                  alt="SmartNews"
                />
              </div>
              <div className="blog-item-desc">
                24時間365日働き続けているシステムの構築・運用をしているエンジニアに使い心地を尋ねた。
              </div>
              <div className="blog-item-cta">
                <a href="/casestudy-smartnews/" className="mr-3 group flex">
                  詳しくはこちら
                  <svg
                    className="mt-[3px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-cta-btn">
          <a href="/casestudy/" className="mr-[10px]">
            導入事例をみる​
            <svg
              className="inline-block"
              width={34}
              height={34}
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx={17} cy={17} r={17} fill="#4EB346" />
              <path
                d="M26.7071 17.7071C27.0976 17.3166 27.0976 16.6834 26.7071 16.2929L20.3431 9.92893C19.9526 9.53841 19.3195 9.53841 18.9289 9.92893C18.5384 10.3195 18.5384 10.9526 18.9289 11.3431L24.5858 17L18.9289 22.6569C18.5384 23.0474 18.5384 23.6805 18.9289 24.0711C19.3195 24.4616 19.9526 24.4616 20.3431 24.0711L26.7071 17.7071ZM6 18L26 18L26 16L6 16L6 18Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
        <HomeCompanies />
        <div className="blog-container blog-container-2">
          <div className="blog-header">
            <h2>サポート</h2>
          </div>
          <div className="blog-section">
            <div className="blog-item md:max-h-[500px]">
              <div className="blog-img blog-img-2">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/blog_Img4_c5a052535f_697b12bfcd.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672981572684&tr=q-100,f-webp"
                  alt="FAQ"
                />
              </div>
              <div className="blog-item-title mb-[15px] md:min-h-[100px]">
                FAQ
              </div>
              <div className="blog-item-desc">
                ライセンス、機能、設定について、当社によくいただく質問に回答しています。
              </div>
              <div className="blog-item-cta">
                <a href="/faq/" className="mr-3 group flex">
                  詳しくはこちら
                  <svg
                    className="mt-[3px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="blog-item md:max-h-[500px]">
              <div className="blog-img blog-img-2">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/blog_Img5_ba853ae2e4_03c938b3d3.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672981572480&tr=q-100,f-webp"
                  alt="インテグレーション ガイド​"
                />
              </div>
              <div className="blog-item-title mb-[15px] md:min-h-[100px]">
                インテグレーション
                <br />
                ガイド​
              </div>
              <div className="blog-item-desc">
                インテグレーション方法を解説した米国PagerDutyのガイドを和訳したものを紹介しています。
              </div>
              <div className="blog-item-cta">
                <a href="/docs-guides/" className="mr-3 group flex">
                  詳しくはこちら
                  <svg
                    className="mt-[3px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="blog-item md:max-h-[500px]">
              <div className="blog-img blog-img-2">
                <img
                  loading="lazy"
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Home/blog_Img6_5924626b71_e14320e445.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672981572639&tr=q-100,f-webp"
                  alt="日本語サポート サイト"
                />
              </div>
              <div className="blog-item-title mb-[15px] md:min-h-[100px]">
                日本語サポート
                <br />
                サイト
              </div>
              <div className="blog-item-desc">
                PagerDutyの使い方や技術情報を掲載。技術サポートへのお問い合わせ窓口としても利用できます。
                <br />
                <span className="text-red-400">
                  {" "}
                  ※弊社経由でご導入のユーザ様のみ。{" "}
                </span>
              </div>
              <div className="blog-item-cta">
                <a
                  href="https://pagerduty-digitalstacks.zendesk.com/"
                  className="mr-3 group flex"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[3px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="schedule-container mb-8">
          <div className="schedule-header">
            <h2 className="text-center">FAQ</h2>
          </div>
          <div className="schedule-sub-header">
            ライセンス、機能、設定について、当社によくいただく質問に回答しています。
          </div>
          <div className="schedule-table">
            <div className="blog-section-with-faq-cta pl-0 pr-0">
              <div className="faq-cta-right-arrow faq-toggle-1">
                <svg
                  width={34}
                  height={34}
                  viewBox="0 0 34 34"
                  fill="none"
                  className="inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx={17} cy={17} r={17} fill="currentColor" />
                  <path
                    d="M26.7071 17.7071C27.0976 17.3166 27.0976 16.6834 26.7071 16.2929L20.3431 9.92893C19.9526 9.53841 19.3195 9.53841 18.9289 9.92893C18.5384 10.3195 18.5384 10.9526 18.9289 11.3431L24.5858 17L18.9289 22.6569C18.5384 23.0474 18.5384 23.6805 18.9289 24.0711C19.3195 24.4616 19.9526 24.4616 20.3431 24.0711L26.7071 17.7071ZM6 18L26 18L26 16L6 16L6 18Z"
                    fill="#55B54D"
                  />
                </svg>
                <p className="font-bold text-black">PagerDutyとは何ですか？</p>
              </div>
              <p
                className="faq-answer faq-answer-1"
                style={{ display: "none" }}
              >
                PagerDutyは監視ツールやアプリケーションからのアラートを受けて、いろいろな方法でインシデントの発生を確実に担当者に通報するシステムです。ダウンタイムやシステムの不正確さから企業を保護するサービスで、他の監視ツールとの統合も可能。チーム全体にとって必要な警告～警報に優先順位を付け、メールやSMS、音声メッセージなどでアラート送信してくれます。
              </p>
            </div>
            <div className="blog-section-with-faq-cta pl-0 pr-0">
              <div className="faq-cta-right-arrow faq-toggle-2">
                <svg
                  width={34}
                  height={34}
                  viewBox="0 0 34 34"
                  fill="none"
                  className="inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx={17} cy={17} r={17} fill="currentColor" />
                  <path
                    d="M26.7071 17.7071C27.0976 17.3166 27.0976 16.6834 26.7071 16.2929L20.3431 9.92893C19.9526 9.53841 19.3195 9.53841 18.9289 9.92893C18.5384 10.3195 18.5384 10.9526 18.9289 11.3431L24.5858 17L18.9289 22.6569C18.5384 23.0474 18.5384 23.6805 18.9289 24.0711C19.3195 24.4616 19.9526 24.4616 20.3431 24.0711L26.7071 17.7071ZM6 18L26 18L26 16L6 16L6 18Z"
                    fill="#55B54D"
                  />
                </svg>
                <p className="font-bold text-black">PagerDutyの費用は?</p>
              </div>
              <p
                className="faq-answer faq-answer-2"
                style={{ display: "none" }}
              >
                Professional
                プランで21ドル（1ユーザー／月：年払い）、Businessプラン41ドル（1ユーザー／月：年払い）になります。詳しくは
                <a
                  href="https://pagerduty.digitalstacks.net/pricelist/"
                  className="text-[#55B54D]"
                >
                  プライスリスト
                </a>
                でご確認ください。
              </p>
            </div>
            <div className="blog-section-with-faq-cta pl-0 pr-0">
              <div className="faq-cta-right-arrow faq-toggle-3">
                <svg
                  width={34}
                  height={34}
                  viewBox="0 0 34 34"
                  fill="none"
                  className="inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx={17} cy={17} r={17} fill="currentColor" />
                  <path
                    d="M26.7071 17.7071C27.0976 17.3166 27.0976 16.6834 26.7071 16.2929L20.3431 9.92893C19.9526 9.53841 19.3195 9.53841 18.9289 9.92893C18.5384 10.3195 18.5384 10.9526 18.9289 11.3431L24.5858 17L18.9289 22.6569C18.5384 23.0474 18.5384 23.6805 18.9289 24.0711C19.3195 24.4616 19.9526 24.4616 20.3431 24.0711L26.7071 17.7071ZM6 18L26 18L26 16L6 16L6 18Z"
                    fill="#55B54D"
                  />
                </svg>
                <p className="font-bold text-black">
                  14日間の無料トライアルはどんなことができますか？
                </p>
              </div>
              <p
                className="faq-answer faq-answer-3"
                style={{ display: "none" }}
              >
                無料トライアル期間中は、全部の機能が使えて、電話やSMSを無料で送信できます。ご購入するまではクレジットカード登録も不要ですので課金されることはありません。無料トライアルの申し込みは
                <a
                  className="text-[#55B54D]"
                  href="https://pagerduty.digitalstacks.net/free-trial-2/?utm_source=google&utm_medium=faq&utm_campaign=g_search01"
                >
                  こちら
                </a>
              </p>
            </div>
            <a href="/faq/" className="block mt-[2em] text-center mx-auto">
              詳しくはこちら＞
            </a>
          </div>
        </div>
      </div>

      <div>
        <ShortNews />
      </div>
    </SiteWrapper>
  );
};
export default GeneratedIndex;
